import React from "react";

import "./Newsletter.scss";

import Layout from "../components/Layout/Layout";

const Newsletter = ({ location }) => {
  return (
    <Layout>
      <div className="grid-container">
        <div className="newsletter">
          <h1>
            News <span className="ampersand">&</span>Trends
          </h1>
          <p>
            Hoop on your S<span className="ampersand">&</span>T journey. Send us
            your offer today!
          </p>
          <div className="double-row">
            <label>
              <span>Title</span>
              <input placeholder=" " />
            </label>
            <label>
              <span>First Name</span>
              <input placeholder=" " />
            </label>
          </div>
          <div className="double-row">
            <label>
              <span>Last Name</span>
              <input placeholder=" " />
            </label>
            <label>
              <span>E-mail</span>
              <input placeholder=" " />
            </label>
          </div>
          <div className="single-row no-border">
            <label>
              <span>Companies</span>
              <input placeholder=" " />
            </label>
          </div>
          <div className="single-row">
            <button className="newsletter-button" type="button">
              Send
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Newsletter;
